import React from "react";
import { useState } from "react";
import { Link } from "gatsby";

const navbarItemStyles = {
  // padding: "2rem 2rem",
  marginRight: "1rem",
}

// TODO: mobile nav

const Nav: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar is-fixed-top is-dark" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src="/images/nav-logo.svg" width="300" height="30" alt="logo" />
        </Link>
        <a role="button" className={`navbar-burger ${menuOpen ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarMain" onClick={() => setMenuOpen(!menuOpen)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarMain" className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
        <div className="navbar-end">
          <Link to="/" className="navbar-item" style={navbarItemStyles}>
            HOME
          </Link>
          <Link to="/about" className="navbar-item" style={navbarItemStyles}>
            ABOUT US
          </Link>
          <Link to="/developers" className="navbar-item" style={navbarItemStyles}>
            DEVELOPERS
          </Link>
          <Link to="/contact" className="navbar-item" style={navbarItemStyles}>
            CONTACT
          </Link>
        </div>
      </div>
    </nav>
  )
};

export default Nav;
