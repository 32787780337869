import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const heroStyles = {
  backgroundImage: "url(/images/about-bg.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "30vh",
}

const sectionStyles = {
  padding: "0",
  // paddingTop: "3rem",
  // backgroundColor: "#082D5E",
}

const columnsStyles = {
  marginBottom: "0",
}

const backgroundImageStyles = {
  backgroundImage: "url(/images/about-img.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "30vh",
}

const contentStyles = {
  maxWidth: "720px",
}

const About: React.FC = () => {
  return (
    <>
      <section className="hero is-medium" style={heroStyles} >
        <div className="hero-body">
          &nbsp;
        </div>
      </section>
      <section id="about" className="section is-medium" style={sectionStyles} >
        <div className="columns" style={columnsStyles}>
          <div className="column is-6-desktop is-4-tablet is-hidden-mobile" style={backgroundImageStyles}>
            &nbsp;
          </div>
          <div className="column is-6-desktop is-8-tablet">
            <div className="p-6 content has-text-dark" style={contentStyles}>
              <h1 className="title is-1 has-text-weight-light">
                ABOUT US
              </h1>
              <p>
                At Consigma Estates, we pride ourselves on being different. Unlike other companies in the industry, we make direct contact with land and property owners, as well as developers in all sectors. This allows us to create meaningful relationships with our clients and provide personalised solutions that meet their unique needs.
              </p>
              <p>
                We believe that the key to success in the property market is collaboration. That's why we work closely with planning consultants, architects, and construction specialists to maximise the outcome for all parties involved. By leveraging our extensive network of industry experts, we can provide you with a comprehensive service that covers every aspect of the planning and development process.
              </p>
              <p>
                At Consigma Estates, we are committed to delivering results that exceed your expectations. Our team consists of highly skilled and experienced professionals who have a deep understanding of the UK planning system and the property market. We take a hands-on approach to every project, and we pride ourselves on our attention to detail and our ability to deliver on time and on budget.
              </p>
              <p>
                We understand that every project is unique, which is why we take the time to understand your specific goals and challenges. Whether you're a landowner looking to maximise the value of your property or a developer seeking to unlock the potential of a site, we have the expertise and resources to help you achieve your objectives.
              </p>
              <p>
                At Consigma Estates, we are passionate about what we do, and we are dedicated to providing our clients with the highest level of service and support. Contact us today to learn more about how we can help you achieve success in the UK property market.        </p>
            </div>
          </div>
          <div className="column is-6-desktop is-4-tablet is-hidden-tablet" style={backgroundImageStyles}>
            &nbsp;
          </div>
        </div>
      </section>
    </>
  )
};

export default About;
