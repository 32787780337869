import * as React from "react"

interface FooterProps {
}

const containerStyles = {
  // minHeight: "30vh",
}

const Footer = ({ }: FooterProps) => (
  <footer className="footer has-background-primary has-text-grey is-size-7 pb-6" style={containerStyles}>
    <div className="content has-text-centered">
      <p>
        © Consigma Estates Ltd. Company number 14670300 (Registered in England & Wales)
      </p>
      {/* <p>
        Registered address 7 Checkpoint Court, Sadler Road, Lincoln, Lincolnshire, LN6 3PW, UK
      </p> */}
    </div>
  </footer>
)

export default Footer
