import "../styles/global.scss"

import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"

import Nav from "../components/nav"
import About from "../components/about"
import Footer from "../components/footer"

const AboutPage: React.FC<PageProps> = () => {
  return (
    <main>
      <Nav />
      <About />
      <Footer />
    </main>
  )
}

export default AboutPage

export const Head: HeadFC = () => (
  <>
    <title>Consigma Estates Ltd - About</title>
    <body className="has-navbar-fixed-top" />
  </>
)
